import React, { Component } from 'react'
import Menu from '../menu/menu'
import Logohome from '../../img/Calendar.svg'
import logSinc from '../../img/showtime.svg'
import logcart from '../../img/cart.svg'
import ebook from '../../img/ebook.svg'
import './home.css';

export default class Home extends Component {
    render() {
        return (
            <>
                <Menu></Menu>
                <section className="seccion_home">
                    <img src={Logohome} alt="" className="bg_home"></img>
                    <div className="contenedor_home">
                        <div className="textbox">
                            <h1>Sincronización de Calendarios para tu propiedad </h1>
                            <p>Numa Calendar es una aplicación para administrar los calendarios de tus propiedades,
                                te permite mantener centralizar todos tus calendarios y ademas poder compartir tu calendario con otras  agencias
                            </p>
                            <a href="numa://numacalendar.com">Comienza ahora</a>
                        </div>

                    </div>
                </section>

                <div className="descripcion_home">
                    <h2>Incrementa tus reservas y ahorate tiempo en las consultas</h2>
                    <p>Numa te permite mantener todos tus calendarios sincronizados y tambien podras compartirlos a tus agencia de preferencia,
                        esto te ayudara en ahorro de tiempo en consultas de fechas disponibles y seleccionar tu mejor propuesta
                    </p>

                </div>


                <div className="fichas_info">
                    <div className="info_dt">
                        <div className="img">
                            <img src={logSinc} alt=""></img>
                        </div>
                        <div className="des">
                            <h3>Evita Reservas duplicadas</h3>
                            <p>sincroniza todos tus calendarios de manera automatica y en tiempo real</p>

                        </div>

                    </div>

                    <div className="info_dt">
                        <div className="img">
                            <img src={ebook} alt=""></img>
                        </div>
                        <div className="des">
                            <h3>Comparte Tus Calendarios</h3>
                            <p>Compartelos con tus agencias locales</p>
                        </div>

                    </div>

                    <div className="info_dt">
                        <div className="img">
                            <img src={logcart} alt=""></img>
                        </div>
                        <div className="des">
                            <h3>Selecciona tu mejor oferta</h3>
                            <p>Puedes seleccionar tu mejor oferta para el alquiler</p>
                        </div>

                    </div>



                </div>


                <div className="contenedor-home" style={{ paddingTop: "50px" }}>
                    <div className="card-web">
                        <div className="des">
                            <div className="fondo">
                                <img src={"https://cdn.pixabay.com/photo/2015/07/17/22/43/student-849825_960_720.jpg"}></img>
                            </div>
                            <div className="contenedor">
                                <h2>Creamos Tu Sitio Web</h2>
                                <p>Te ayudamos a crear tu sitio web unico, donde nos enfocamos en brindar
                                    una excelente experiencia de usuario e integrar  las herramientas de consulta de reservas</p>
                                {/* <p>Nos ajustamos a tu dominio y le desarrollamos a tu medida</p>
                                <p>Sincronizamos los calendarios de tus propiedades administradas</p>
                                <p>Te asesoramos en tu modelo de reservas</p> */}
                                <button>Saber Más</button>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="contenedor-home" style={{ paddingBottom: "60px" }}>
                    <h2>Nos integramos con otras plataformas</h2>

                    <div className="cont-card">
                        <div className="card-int">
                            <span>
                                <img src={"https://www.lodgify.com/wp-content/uploads/2019/12/logo_airbnb.svg"} alt="logo airbnb"></img>
                            </span>
                        </div>

                        <div className="card-int">
                            <span>
                                <img src={"https://www.lodgify.com/wp-content/uploads/2019/12/logo_bookingcom.svg"} alt="logo booking"></img>
                            </span>
                        </div>

                        <div className="card-int">
                            <span>
                                <img src={"https://www.lodgify.com/wp-content/uploads/2019/12/Logo_Expedia.svg"} alt="logo Expidia"></img>
                            </span>
                        </div>

                        <div className="card-int">
                            <span>
                                <img src={"https://mifinka.com/static/media/logomenu.36b82459.png"} alt={"logo mifinka.com"}></img>
                            </span>
                        </div>
                    </div>
                </div>

                <footer>
                    <div className="contenedor-footer">
                        <div className="sec aboutud">
                            <h4>Acerca De Nosotros</h4>
                            <p>Somos una empresa que te ayudara a mejorar la experiencia de usuarios en el alquiler de propiedades,
                                evitando reservas duplicadas y permitiendo mantener a tus agentes sincronizados</p>
                            <ul className="sci">
                                <li><a href="numa://numacalendar.com"><i className="uil uil-google-play"></i></a></li>
                            </ul>
                        </div>

                        <div className="sec quicklinks">
                            <h4>Menu</h4>
                            <ul>
                                <li><a href="#/">Inicio</a></li>
                                <li><a href="#/">Precios</a></li>
                                <li><a href="#/">Registro</a></li>
                                <li><a href="#/">Terminos Y Condiciones</a></li>
                            </ul>

                        </div>

                        <div className="sec contacto">
                            <h4>Contactanos</h4>
                            <ul className="infor">
                                <li>
                                    <span><i className="uil uil-map-marker-plus"></i></span>
                                    <span>Medellin, Antioquia</span>
                                </li>
                                <li>
                                    <span><i className="uil uil-phone-alt"></i></span>
                                    <p><a href="tel:+573016182967">+57 3016182967</a></p>
                                </li>
                                <li>
                                    <span><i className="uil uil-envelope-check"></i></span>
                                    <p><a href="mailto: comercial@numacalendar.com">comercial@numacalendar.com</a></p>
                                </li>
                            </ul>

                        </div>
                    </div>

                </footer>
                <div className="copyright">
                    <p>Copyright 2021 Impacto Tecnologico. All Rights Reserved</p>
                </div>
            </>

        )
    }
}
