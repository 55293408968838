import React, { Component } from 'react'
import Menu from './Menu'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/es';
import { DayPickerRangeController } from 'react-dates';
import urban from '../../img/urban.png'
import backgr from '../../img/numavisual.png'
//import Calendariosjson from '../../Models/calendarios.json'
import InfiniteScroll from "react-infinite-scroll-component";


function getToken() {
    const tokenString = localStorage.getItem('tokenapp');
    const userToken = JSON.parse(tokenString);
    return userToken
}


export default class Panel extends Component {
    constructor(props) {
        super();
        this.myRef = React.createRef()
        this.state = {
            width: 0,
            height: 0,

            iddivselect: 0,
            referenciaslide: [],

            startDate: moment(),
            endDate: moment(),
            focusedInput: "startDate",
            cantidadmeses: 2,
            activarmodal: false,
            orientaciondatagrid: "horizontal",

            loadfecha: false,

            descripcion: "",
            precio: "",
            token: "",

            tokencalendario: "",
            descripcioncalendario: "",
            nombrecalendario: "",
            loadcalendario: false,
            data: [],
            datanotas: [],
            datasolicitudes: [],

            Fechasbloqueadas: [],

            loading: false,


            hasMore: true,
            parametrobusqueda: 0,
            idbusqueda: 0,
        }
    }

    resizecarusel = () => {
        const ancho = window.innerWidth;
        const sampwidth = document.querySelector('#carusel-img').clientWidth;

        if (ancho < 800) {
            this.setState({ orientaciondatagrid: "vertical" });
        } else {
            this.setState({ orientaciondatagrid: "horizontal" });
        }

        //const incno = this.props.cantidad;
        var itemWidth = 200;
        if (ancho >= 1400) {
            itemWidth = parseInt(sampwidth) / parseInt(8);
        }
        else if (ancho >= 992) {
            itemWidth = parseInt(sampwidth) / parseInt(5);
        }
        else if (ancho >= 768) {
            itemWidth = parseInt(sampwidth) / parseInt(3);
        }
        else {
            itemWidth = sampwidth / parseInt(2);
        }

        var slidest = document.getElementsByClassName("cont_prop");
        for (var j = 0; j < slidest.length; j++) {
            slidest.item(j).style.minWidth = (itemWidth - 5) + "px";
        }
        /*    var slidest = document.getElementsByClassName("card_btn");
           for (var j = 0; j < slidest.length; j++) {
               slidest.item(j).style.width = (itemWidth - 20) + "px";
           } */

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    async componentDidMount() {
        moment.locale("es");
        window.addEventListener('resize', this.updateWindowDimensions);
        this.resizecarusel();
        const ancho = window.innerWidth;
        if (ancho < 800) {
            this.setState({ orientaciondatagrid: "vertical" });
        }

        const token = getToken();
        if (token) {
            this.setState({ token: token.cunico })
        } else {
            this.setState({ token: "" })
            this.props.history.push('/');
        }
        //console.log(global.urlservidor + `compartir2?token=${token.cunico}`);
        const query = await fetch(global.urlservidor + `compartir2?token=${token.cunico}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if (query.ok) {
            const data = await query.json();
            this.setState({ data: data })
            console.log(data);
        }
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        this.resizecarusel();
    }

    setslideshow = (node) => {
        this.setState({ referenciaslide: node })
    }

    anterior = (e) => {
        e.preventDefault();
        this.backdiv();
    }

    siguiente = (e) => {
        e.preventDefault();
        this.nextdiv();
    }

    nextdiv = () => {

        if (this.state.referenciaslide.children.length > 0) {
            const primerelemento = this.state.referenciaslide.children[0];

            if (this.state.referenciaslide.children[1]) {
                const segundoelemento = this.state.referenciaslide.children[1];
                this.setState({ iddivselect: parseInt(segundoelemento.dataset.id) });
            }

            //Se establece la transicion primer slide
            var referenciaslider = this.state.referenciaslide;
            referenciaslider.style.transition = '300ms ease-out all';

            //obtenemos el tamaño(ancho) del slide
            const sizeslide = this.state.referenciaslide.children[0].offsetWidth;
            referenciaslider.style.transform = 'translateX(-' + sizeslide + 'px)';

            //cambio de posicion
            const trasnsicion = () => {
                referenciaslider.style.transition = 'none'
                referenciaslider.style.transform = 'translateX(0px)'

                //agrega un elemento                
                referenciaslider.appendChild(primerelemento);
                referenciaslider.removeEventListener('transitionend', trasnsicion);
            }
            //eventlistener  Se utliliza cuando se espera algo
            referenciaslider.addEventListener('transitionend', trasnsicion);


        }
    }

    backdiv = () => {
        if (this.state.referenciaslide.children.length > 0) {

            const index = this.state.referenciaslide.children.length - 1;
            const ultimo = this.state.referenciaslide.children[index];
            //console.log(ultimo);
            this.setState({ iddivselect: parseInt(ultimo.dataset.id) });

            var referenciaslider = this.state.referenciaslide;

            referenciaslider.insertBefore(ultimo, referenciaslider.firstChild);

            referenciaslider.style.transition = 'none'

            const sizeslide = this.state.referenciaslide.children[0].offsetWidth;
            referenciaslider.style.transform = 'translateX(-' + sizeslide + 'px)'

            setTimeout(() => {
                referenciaslider.style.transition = '300ms ease-out all'
                referenciaslider.style.transform = 'translateX(0)';
            }, 30)

        }
    }

    enviardatosservidor = async (collection, url) => {
        const querys = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                collection
            )
        })
        const retorno = await querys.json();
        return retorno;
    }

    onFocusChange = (focusedInput) => {
        try {
            this.setState({
                focusedInput: !focusedInput ? "startDate" : focusedInput,
            });
            const value = !focusedInput ? "startDate" : focusedInput
            if (value === "startDate") {
                if (this.myRef) {
                    this.myRef.current.classList.toggle('activo')
                    this.setState({ loadfecha: true })
                }
            } else {

                if (this.myRef) {
                    if (this.state.loadfecha) {
                        this.myRef.current.classList.toggle('activo')
                        this.setState({ loadfecha: false })
                    }

                }
            }
        } catch {

        }
    }

    activarmenulat = (e) => {
        e.preventDefault();
        if (this.myRef) {
            this.setState({ loadfecha: false })
            this.myRef.current.classList.toggle('activo')
        }
    }

    onchangetext = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    vercalendario = async (detalle, tokenfin, titulo, id) => {
        //console.log(global.urlservidor + `icalfecha?idfinka=${id}`);
        this.setState({
            loading: true,
            datanotas: [],
            datasolicitudes: [],
            Fechasbloqueadas: [],
        })
        const query = await fetch(global.urlservidor + `icalfecha?idfinka=${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if (query.ok) {
            const data = await query.json();
            if (data[0].status !== false) {
                var array_fecha = []
                for (var i in data[1].total.fechareservado) {
                    array_fecha[i] = data[1].total.fechareservado[i].fecha;
                }
                //console.log(array_fecha)
                this.setState({ Fechasbloqueadas: array_fecha })
            }
        }

        const querynotas = await fetch(global.urlservidor + `notas?id=${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if (querynotas.ok) {
            const data = await querynotas.json();
            this.setState({ datanotas: data })
        }



        let collection = {}
        collection.idcliente = this.state.token;
        collection.idcalendario = id
        collection.busquedain = 0;
        collection.busquedafin = 5;
        console.log(collection);
        //const retorno = await this.enviardatosservidor(collection, global.urlservidor + "icalconsulta", "POST");
        const retorno = await this.enviardatosservidor(collection, global.urlservidor + "solicitudenviadas", "POST");

        this.setState({ parametrobusqueda: 5, idbusqueda: id })
        if (retorno[0].status) {
            console.log(retorno[0].data)
            this.setState({ datasolicitudes: retorno[0].data })
        }

        this.setState({
            tokencalendario: tokenfin,
            descripcioncalendario: detalle,
            nombrecalendario: titulo,
            loadcalendario: true,
            loading: false,
        })

    }

    onsubmit = async (e) => {
        e.preventDefault();
        let collection = {}
        collection.idcliente = this.state.token;
        collection.idfinca = this.state.tokencalendario;
        collection.nota = this.state.descripcion;
        collection.precio = this.state.precio;
        collection.fechain = this.state.startDate.format('YYYY/MM/DD');
        collection.fechaout = this.state.endDate.format('YYYY/MM/DD');
        console.log(collection)

        const retorno = await this.enviardatosservidor(collection, global.urlservidor + "solicitud", "POST");
        console.log(retorno)
        if (retorno[0].status) {
            this.setState({ descripcion: "", precio: "" })
            if (this.myRef) {
                this.setState({ loadfecha: false })
                this.myRef.current.classList.toggle('activo')
            }

            //this.fetchMoreData();
            let collection = {}
            collection.idcliente = this.state.token;
            collection.idcalendario = this.state.idbusqueda
            collection.busquedain = 0;
            collection.busquedafin = 5;
            console.log(collection);            
            const retorno = await this.enviardatosservidor(collection, global.urlservidor + "solicitudenviadas", "POST");
            this.setState({ parametrobusqueda: 5})
            if (retorno[0].status) {
                console.log(retorno[0].data)
                this.setState({ datasolicitudes: retorno[0].data })
            }



        }

    }

    isDayBlocked = day => {
        const fechasreservado = this.state.Fechasbloqueadas;
        return fechasreservado.some(date => day.isSame(date, 'day'));
    }

    isOutsideRange = day =>
        day.isBefore(moment().subtract(1, "days"));


    fetchMoreData = async () => {

        const busqueda = this.state.parametrobusqueda;
        let collection = {}
        collection.idcliente = this.state.token;
        collection.idcalendario = this.state.idbusqueda
        collection.busquedain = busqueda;
        collection.busquedafin = (busqueda + 5);

        this.setState({ parametrobusqueda: (busqueda + 5) })
        console.log(collection);
        const retorno = await this.enviardatosservidor(collection, global.urlservidor + "solicitudenviadas", "POST");
        if (retorno[0].status) {
            if (retorno[0].data.length === 0) {
                this.setState({ hasMore: false })
            } else {
                this.setState({ hasMore: true })
                let jsoninit = this.state.datasolicitudes;
                let jsonnew = retorno[0].data;
                jsoninit = jsoninit.concat(jsonnew);
                console.log("-----");
                console.log(jsoninit);
                this.setState({ datasolicitudes: jsoninit })
            }

        }
    }

    render() {
        return (
            <div className="dashboard">
                <Menu></Menu>
                <div className="panel_p" style={{ background: "#ffffff" }}>
                    <div className="contenido__panel">
                        <h1 className="headertxt">Panel Calendarios</h1>
                        <p>Consulta y crea solicitudes para tus usuarios</p>

                        <div className="filter_search">
                            <i className="uil uil-search"></i>
                            <input placeholder="Buscar.."></input>
                        </div>

                        <div className="titulo">
                            <h2>Calendarios Compartidos</h2>
                            <p>Los Calendarios que han sido compartidos se veran aqui, dando clic sobre la imagen puedes ver las fechas disponibles de cada una de las propiedades</p>
                        </div>


                        <div className="carusel" id="carusel-img">
                            <div className="propiedades" ref={this.setslideshow}>
                                {this.state.data.map((e, id) => (

                                    <div className="cont_prop" key={id}>

                                        <div className="card_btn" onClick={this.vercalendario.bind(this, e.descripcion, e.token, e.titulo, e.id)}>
                                            <img src={"https://mifinka.com/backnode/numa/fincas/" + e.imagen} alt=""></img>
                                            <div className="descripcion_card">
                                                <h2>{e.titulo}</h2>
                                                {/* <p>Finca ubicada en medellin antioquia, llamar para confirma fecha final del reserva</p> */}
                                                <button onClick={this.vercalendario.bind(this, e.descripcion, e.token, e.titulo, e.id)} style={{ padding: "3px 15px", border: "1px solid #ddd", borderRadius: "40px", fontSize: "12px", marginTop: "10px", cursor: "pointer" }}>Ver Calendario</button>
                                            </div>

                                        </div>

                                    </div>
                                ))}


                            </div>
                            <div className="carusel-controles">
                                <button style={{ left: -8 }} aria-label="Left Align" onClick={this.anterior}>
                                    <i className="uil uil-angle-left"></i>
                                </button>
                                <button style={{ right: -8 }} aria-label="Right Align" onClick={this.siguiente}>
                                    <i className="uil uil-angle-right-b"></i>
                                </button>
                            </div>

                        </div>

                        {this.state.loading ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <span style={{ marginTop: 160 }}>Cargando Calendario...</span>
                                <div class="loader">
                                    <div class="inner one"></div>
                                    <div class="inner two"></div>
                                    <div class="inner three"></div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {this.state.data.length < 0 && (

                                    <div className="seleccion">
                                        <div className="contenido">
                                            <div className="descripcion">
                                                <div className="texto">
                                                    <h1>La mejor herramienta para mejorar tus reservas</h1>
                                                </div>
                                                <div className="textop">
                                                    <p>Administra y sincroniza todos tus calendarios, gasta menos tiempo en consultas</p>
                                                </div>
                                                <div className="ir">
                                                    <button>Comienza ahora</button>
                                                </div>
                                            </div>
                                            <div className="imagen">
                                                <img src={urban}></img>
                                            </div>
                                        </div>
                                    </div>

                                )}

                                {this.state.loadcalendario && (

                                    <div className="calendario">
                                        <h2>{"Calendario De " + this.state.nombrecalendario}</h2>
                                        <p>{this.state.descripcioncalendario}</p>

                                        <div className="dt_calendar">
                                            <DayPickerRangeController
                                                startDateId="startDate"
                                                endDateId="endDate"
                                                startDatePlaceholderText="Ingreso"
                                                endDatePlaceholderText="Salida"
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onDatesChange={({ startDate, endDate }) => { this.setState({ startDate: startDate, endDate: endDate }) }}

                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={this.onFocusChange}
                                                isDayBlocked={this.isDayBlocked}
                                                //onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}

                                                //initialVisibleMonth={() => !this.state.startDate ? moment().subtract(1, 'M') : this.state.startDate }
                                                enableOutsideDays={false}
                                                isOutsideRange={this.isOutsideRange}

                                                withPortal={this.state.activarmodal}              //Modal
                                                withFullScreenPortal={false}    //Pantalla completa                                
                                                orientation={this.state.orientaciondatagrid}
                                                appendToBody={true}
                                                displayFormat={"DD/MM/YYYY"}
                                                showDefaultInputIcon={true}
                                                numberOfMonths={this.state.cantidadmeses}
                                                showInputs={true}
                                                noBorder={true}
                                                keepOpenOnDateSelect={false}
                                                // customArrowIcon={<i class="fa fa-angle-right"></i>}                
                                                // showClearDates ={true}      
                                                //datasolicitudes
                                                renderDayContents={(day) => {

                                                    const retorno = this.state.datasolicitudes.filter((e) => {
                                                        if (e.aprobado === 1) {
                                                            if (day.format('DD-MM-YYYY') === moment(e.fechain).format('DD-MM-YYYY') ||
                                                                day.format('DD-MM-YYYY') === moment(e.fechaout).format('DD-MM-YYYY')) {
                                                                return true
                                                            } else {
                                                                if (moment(day).isBetween(moment(e.fechain), moment(e.fechaout))) {
                                                                    return true
                                                                } else {
                                                                    return false
                                                                }
                                                            }
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                    )

                                                    if (retorno.length > 0) {
                                                        if (moment(day).isBetween(moment(retorno[0].fechain), moment(retorno[0].fechaout))) {
                                                            return <td style={{ background: "#ffc107", border: "none", width: "38px", height: "38px", color: "#212833" }}>{day.format('DD')}</td>;
                                                        } else {
                                                            if (day.format('DD-MM-YYYY') === moment(retorno[0].fechaout).format('DD-MM-YYYY')) {
                                                                return <td style={{ background: "#ffc107", border: "none", width: "38px", height: "38px", color: "#212833" }}>
                                                                    {day.format('DD')}
                                                                </td>;
                                                            } else {
                                                                return <td style={{ border: "none", width: "38px", height: "38px" }}>{day.format('DD')}</td>;
                                                            }
                                                        }
                                                    } else {
                                                        return <td style={{ border: "none", width: "38px", height: "38px" }}>{day.format('DD')}</td>;
                                                    }
                                                }}

                                            />

                                        </div>

                                        <div className="reglas">
                                            <h2>Notas Generales</h2>
                                            {this.state.datanotas.map((e) => (
                                                <div className="card_notas" key={e.id}>
                                                    <div className="flexlef">
                                                        <i className="uil uil-rocket"></i>
                                                        <h3>{e.titulo}</h3>
                                                    </div>

                                                    <div>
                                                        <span>{e.descripcion}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>


                                        <div className="reglas">
                                            <h2>Solicitudes Enviadas</h2>


                                            <InfiniteScroll
                                                dataLength={this.state.datasolicitudes.length}
                                                next={this.fetchMoreData}
                                                hasMore={this.state.hasMore}
                                                loader={<h4>Cargando Solicitudes Anteriores...</h4>}
                                                inverse={false}
                                                endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                        <b>Buscar Alguna</b>
                                                    </p>
                                                }
                                            >
                                                {this.state.datasolicitudes.map((e) => (
                                                    <div className="card-solitudes" key={e.id} >
                                                        <div className="info">
                                                            <span>{e.titulo}</span>
                                                            <p style={{ marginTop: "20px" }}>Reservación  del <b>{moment(e.fechain).format('LL')}</b> al <b>{moment(e.fechaout).format('LL')}</b></p>
                                                            <p>{e.nota}</p>

                                                            <div className="estado">
                                                                <span style={{
                                                                    background: (e.aprobado === null) ? "#414e4e" : (e.aprobado === 1) ? "#ffc107" : "#e91e63",
                                                                    color: (e.aprobado === null) ? "#fff" : (e.aprobado === 1) ? "#212833" : "#fff",
                                                                    border: "none"
                                                                }}>
                                                                    {(e.aprobado === null) ? "Pendiente" : (e.aprobado === 1) ? "Aprobado" : "Rechazada"}
                                                                </span>
                                                            </div>

                                                        </div>
                                                        <div className="btn-dt">
                                                            {/* <button>
                                                                <i className="uil uil-trash-alt"></i>
                                                            </button> */}
                                                        </div>
                                                    </div>
                                                ))}

                                            </InfiniteScroll>


                                        </div>

                                    </div>

                                )}
                            </>

                        )}


                        <div className="observaciones" ref={this.myRef} >
                            <div className="contenido_ob">
                                <span>Fechas Selecionadas</span>

                                {this.state.loadfecha &&
                                    <p>{this.state.startDate.format('LL') + " Al " + this.state.endDate.format('LL')}</p>
                                }


                                <form className="form_calendar">
                                    <div className="grupo_input">
                                        <label>Mensaje</label>
                                        <textarea placeholder=""
                                            rows={4}
                                            autoComplete="off"
                                            onChange={this.onchangetext}
                                            name="descripcion"
                                            value={this.state.descripcion}
                                        ></textarea>
                                    </div>
                                    <div className="grupo_input">
                                        <label>Precio</label>
                                        <input placeholder="$00.00"
                                            autoComplete="off"
                                            onChange={this.onchangetext}
                                            name="precio"
                                            value={this.state.precio}

                                        ></input>
                                    </div>

                                    <div className="btn_observacion">
                                        <button onClick={this.onsubmit}>Enviar</button>
                                    </div>

                                </form>

                                <button className="btn_close" onClick={this.activarmenulat}><i className="uil uil-times"></i></button>

                            </div>
                        </div>


                    </div>

                </div>


            </div >
        )
    }
}
