import React, { Component } from 'react'
import Menu from '../menu/menu'

export default class Terminos extends Component {
    render() {
        return (
            <>
                <Menu></Menu>

                <div className="descripcion_home">
                    <div className="contenedor-term">
                        <h1>Politica De Privacidad Y Terminos</h1>

                        <div className="descripcion-con">
                            <p className="date-term"><b>Políticas de privacidad NUMA</b>
                            <br/><br/>
                                Numa Calendar su política de privacidad incluye términos de servicio y condiciones de manejo privado Comercial. Esta Política se aplica a la información que obtenemos al uso de nuestros servicios y puedes cambiar a medida que evoluciones nuestros servicios. Se notificará los cambios realizados en esta política de privacidad publicándolos en este sitio web que debe de verificar periódicamente. Si no está de acuerdo con esta política de privacidad, no descarga la aplicación o desinstálela.
                                <br/><br/>
                                <b>1 Recopilación y uso de la Información</b>
                                <br/><br/>
                                Recopilamos la información necesaria para configurar su cuenta para utilizar nuestros Servicios, como su dirección de correo electrónico, sus contraseñas, su nombre, su número de teléfono, su empresa. Para configurar una cuenta, le pediremos que ingrese una cantidad limitada de información personal necesaria para establecer la conexión. Parte de la información también se puede utilizar para enviar notificaciones a través de solicitudes POST (de servidor a servidor), a través de SMS, a través de notificaciones push a la aplicación o por correo electrónico a su dirección.
                                En general, procesamos su información de manera que lo ayude a aprovechar al máximo el uso de su cuenta y nuestros Servicios, o para comunicarse con usted.<br/><br/>
                                Usamos cookies en nuestros sitios web y software para proporcionar una mejor experiencia de usuario, para recordarle cierta información personal en nuestro sitio web y para estadísticas, mediante el uso de píxeles de seguimiento (Google Analytics).
                                A través de las funciones publicitarias de Google Analytics, recopilamos información para remarketing, informes de impresiones de la Red de Display de Google, informes demográficos y de intereses. Con estas características, Google Analytics recopila datos con fines publicitarios, incluida la recopilación de datos a través de identificadores y cookies publicitarias. Para optar por no recopilar esta información, siga esta exclusión voluntaria de Google Analytics .
                                <br/><br/>
                                Nuestra empresa comparte estos datos con servicios o sitios web de terceros.
                                La compañía puede usar su información personal, de vez en cuando, para comunicarse con usted sobre nuevas funciones, nuevas actualizaciones y cosas que suceden, utilizando su dirección de correo electrónico, su sitio web o su número de teléfono.
                                Nuestros servidores centrales pueden almacenar temporal y parcialmente información confidencial sobre sus clientes cada vez que se descargan algunas reservas para su propiedad desde las OTA conectadas. Estos detalles pueden contener datos sobre el nombre del cliente, correo electrónico, teléfono, dirección completa. Recopilamos esta información para transferir los datos al software channel manager instalado en su sitio web y para brindar un mejor servicio. Como empresa que cumple con PCI, se nos permite almacenar parcial y temporalmente los datos de la tarjeta de crédito, pero actualmente no estamos procesando ningún pago en su nombre.
                                <br/><br/>

                                <b>2. Acceso y divulgación de información</b>
                                <br/><br/>
                                No compartimos, alquilamos ni vendemos su información personal a terceros. Somos una empresa de desarrollo de software y un proveedor de servicios (administrador de canales), por lo que no estamos en el negocio de vender su información.
                                Nuestra empresa podría divulgar su información solo cuando: tengamos su consentimiento explícito.
                                <br/><br/>
                                Numa calendar no hace pública su información personal. Sin embargo, tenga en cuenta que el nombre de su empresa (nombre de la finca), el logotipo de la empresa y Fotos personales  pueden utilizar en la página de cartera pública de nuestro sitio web o app, mientras su suscripción esté activa. Si no desea que este tipo de información se publique en nuestro sitio web, puede contactarnos para solicitar que eliminemos los detalles de su empresa de nuestro sitio público.
                                <br/><br/>
                                <b>3. Transferencia y almacenamiento de datos</b>
                                <br/><br/>
                                Tenemos que almacenar y transferir algunos datos con regularidad para poder ofrecer nuestro servicio de la mejor manera. La mayoría de los datos relacionados con sus reservas se almacenarán en la base de datos de nuestro sitio web. Numa Calendar no es responsable de ninguna divulgación de datos debido a algunos problemas en su sitio web, es su responsabilidad adoptar medidas de seguridad para proteger sus propios datos.
                                Los datos también se transfieren desde nuestros servidores centrales a su propio sitio web. El único propósito de almacenar estos datos sensibles es permitirle acceder a esta información privada.
                                Privacidad de los niños
                                <br/><br/>
                                Estos Servicios no se dirigen a ninguna persona menor de 18 años. No recopilo a sabiendas información de identificación personal de niños menores de 13 años. En el caso de que descubra que un niño menor de 18 años me ha proporcionado información personal, la elimino inmediatamente de nuestros servidores. Si usted es padre o tutor y sabe que su hijo nos ha proporcionado información personal, comuníquese conmigo para que pueda realizar las acciones necesarias.
                                <br/><br/>

                                <b>4. Portafolio e información personal de Numa Calendar</b>
                                <br/><br/>
                                Podemos utilizar información básica sobre su empresa y sitio web para actualizar nuestra sección de Portafolio y gualmente la foto del perfil ingresado. Esto significa que la dirección de su sitio web, una captura de pantalla de su sitio y el nombre de su empresa / propiedad pueden formar parte de nuestra sección de Portafolio. Dicha información nunca se compartirá con ninguna empresa de terceros, pero los detalles pueden estar visibles en una página pública de este sitio web. Puede solicitar la actualización o la eliminación de su información personal en cualquier momento comunicándose con nosotros. Al realizar un pedido desde este sitio web, acepta automáticamente estos términos.
                                <br/><br/>
                                <b>5. Preguntas y contactos</b>
                                <br/><br/>
                                No dude en utilizar nuestros E- mail o WhatsApp de contacto para comunicarse con nosotros para cualquier tipo de solicitud. Nos comunicaremos con usted por correo electrónico. De lo contrario, si prefiere otras formas de comunicarse con nosotros para solicitudes especiales, no dude en contactarnos por correo postal a la dirección especificada en nuestros Términos y Condiciones.
                                <br/><br/>
                                <b>Política de privacidad</b>
                                <br/><br/>
                                Estas políticas están sujetas a cambios sin previo aviso, de vez en cuando a nuestro exclusivo criterio. La empresa Impacto Tecnológico le notificará los cambios realizados en la Política de privacidad mediante su publicación en este sitio web. Si no está de acuerdo con estas políticas, no descargue ningún software.
                                Esta política es efectiva a partir del 25/08/21.
                            </p>
                        </div>

                    </div>
                </div>
            </>
        )
    }

}