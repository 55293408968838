import React, { Component } from 'react'
import Menu from '../menu/menu'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/es';
import { DayPickerRangeController } from 'react-dates';
import urban from '../../img/urban.png'
import InfiniteScroll from "react-infinite-scroll-component";
import logo from '../../img/logo.png'

export default class Calendarlogin extends Component {

    constructor(props) {
        super();
        this.myRef = React.createRef()
        this.state = {
            width: 0,
            height: 0,

            iddivselect: 0,
            referenciaslide: [],

            startDate: moment(),
            endDate: moment(),
            focusedInput: "startDate",
            cantidadmeses: 2,
            activarmodal: false,
            orientaciondatagrid: "horizontal",

            loadfecha: false,

            descripcion: "",
            precio: "",
            token: "",

            tokencalendario: "",
            descripcioncalendario: "",
            nombrecalendario: "",
            loadcalendario: false,
            data: [],
            datanotas: [],
            datasolicitudes: [],

            Fechasbloqueadas: [],

            loading: false,


            hasMore: true,
            parametrobusqueda: 0,
            idbusqueda: 0,
            imagenpropiedad: "",
        }
    }

    enviardatosservidor = async (collection, url) => {
        const querys = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                collection
            )
        })
        const retorno = await querys.json();
        return retorno;
    }

    onchangetext = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    async componentDidMount() {
        moment.locale("es");
        //window.addEventListener('resize', this.updateWindowDimensions);
        //this.resizecarusel();
        const ancho = window.innerWidth;
        if (ancho < 800) {
            this.setState({ orientaciondatagrid: "vertical" });
        }

        this.setState({
            loading: true,
            datanotas: [],
            datasolicitudes: [],
            Fechasbloqueadas: [],
        })

        const validarurl = this.props.match.params.token;
        console.log(global.urlservidor + `icalfecha/fechacompartidas?idfinka=${validarurl}`)
        const query = await fetch(global.urlservidor + `icalfecha/fechacompartidas?idfinka=${validarurl}`, {
            //const query = await fetch(`http://localhost:4900/aplicacion/icalfecha/fechacompartidas?idfinka=${validarurl}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })

        if (query.ok) {
            const data = await query.json();
            if (data[0].status !== false) {
                var array_fecha = []
                for (var i in data[1].total.fechareservado) {
                    array_fecha[i] = data[1].total.fechareservado[i].fecha;
                }

                const querynotas = await fetch(global.urlservidor + `notas?id=${data[1].total.datapropiedad.id}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const datanotas = await querynotas.json();

                console.log(data[1].total.datapropiedad)
                this.setState({
                    Fechasbloqueadas: array_fecha,
                    tokencalendario: validarurl,
                    descripcioncalendario: data[1].total.datapropiedad.descripcion,
                    nombrecalendario: data[1].total.datapropiedad.titulo,
                    imagenpropiedad: data[1].total.datapropiedad.imagen,
                    datanotas: datanotas,
                    loadcalendario: true,
                    loading: false,
                })

            }

        }


    }


    isDayBlocked = day => {
        const fechasreservado = this.state.Fechasbloqueadas;
        return fechasreservado.some(date => day.isSame(date, 'day'));
    }



    render() {
        return (
            <>
                <Menu></Menu>

                {!this.state.loading ? (

                    <>
                        <div className='contenedor-banner-user'>
                            <div className='image-bg'>
                                <img src={(this.state.imagenpropiedad === "") ? "https://cdn.pixabay.com/photo/2017/06/09/09/30/swimming-pool-2386261_640.jpg" : "https://numacalendar.com/backnode/numa/fincas/" + this.state.imagenpropiedad + ".jpg"} alt=""></img>
                            </div>

                            <div className='descripcion-user'>
                                <div className='contenedor-userdes'>
                                    <h1>{"Calendario"}</h1>
                                    <h2>{this.state.nombrecalendario}</h2>
                                    <p>{"Estamos emocionados de compartirte nuestras disponibilidades, aumenta tus reserva consultando nuestra disponibilidad de manera agil"}</p>

                                    <span>Consulta La disponibilidad</span>
                                </div>
                            </div>
                        </div>


                        <div className="panel_p" style={{ background: "#ffffff", left: "0", width: "100%", minHeight: "200px" }}>


                            {this.state.loadcalendario && (

                                <div className="calendario">
                                    <h2>{"Calendario De " + this.state.nombrecalendario}</h2>
                                    <p>{this.state.descripcioncalendario}</p>

                                    <div className="dt_calendar">
                                        <DayPickerRangeController
                                            startDateId="startDate"
                                            endDateId="endDate"
                                            startDatePlaceholderText="Ingreso"
                                            endDatePlaceholderText="Salida"
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onDatesChange={({ startDate, endDate }) => { this.setState({ startDate: startDate, endDate: endDate }) }}

                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={this.onFocusChange}
                                            isDayBlocked={this.isDayBlocked}
                                            //onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}

                                            //initialVisibleMonth={() => !this.state.startDate ? moment().subtract(1, 'M') : this.state.startDate }
                                            enableOutsideDays={false}
                                            isOutsideRange={this.isOutsideRange}

                                            withPortal={this.state.activarmodal}              //Modal
                                            withFullScreenPortal={false}    //Pantalla completa                                
                                            orientation={this.state.orientaciondatagrid}
                                            appendToBody={true}
                                            displayFormat={"DD/MM/YYYY"}
                                            showDefaultInputIcon={true}
                                            numberOfMonths={this.state.cantidadmeses}
                                            showInputs={true}
                                            noBorder={true}
                                            keepOpenOnDateSelect={false}
                                            renderDayContents={(day) => {
                                                const retorno = this.state.datasolicitudes.filter((e) => {
                                                    if (e.aprobado === 1) {
                                                        if (day.format('DD-MM-YYYY') === moment(e.fechain).format('DD-MM-YYYY') ||
                                                            day.format('DD-MM-YYYY') === moment(e.fechaout).format('DD-MM-YYYY')) {
                                                            return true
                                                        } else {
                                                            if (moment(day).isBetween(moment(e.fechain), moment(e.fechaout))) {
                                                                return true
                                                            } else {
                                                                return false
                                                            }
                                                        }
                                                    } else {
                                                        return false
                                                    }
                                                }
                                                )
                                                if (retorno.length > 0) {
                                                    if (moment(day).isBetween(moment(retorno[0].fechain), moment(retorno[0].fechaout))) {
                                                        return <td style={{ background: "#ffc107", border: "none", width: "38px", height: "38px", color: "#212833" }}>{day.format('DD')}</td>;
                                                    } else {
                                                        if (day.format('DD-MM-YYYY') === moment(retorno[0].fechaout).format('DD-MM-YYYY')) {
                                                            return <td style={{ background: "#ffc107", border: "none", width: "38px", height: "38px", color: "#212833" }}>
                                                                {day.format('DD')}
                                                            </td>;
                                                        } else {
                                                            return <td style={{ border: "none", width: "38px", height: "38px" }}>{day.format('DD')}</td>;
                                                        }
                                                    }
                                                } else {
                                                    return <td style={{ border: "none", width: "38px", height: "38px" }}>{day.format('DD')}</td>;
                                                }
                                            }}

                                        />

                                    </div>

                                    {this.state.datanotas.length > 0 && (
                                        <div className="reglas">
                                            <h2>Notas Generales</h2>
                                            {this.state.datanotas.map((e) => (
                                                <div className="card_notas" key={e.id}>
                                                    <div className="flexlef">
                                                        <i className="uil uil-rocket"></i>
                                                        <h3>{e.titulo}</h3>
                                                    </div>

                                                    <div>
                                                        <span>{e.descripcion}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </div>
                            )}
                        </div>

                        <div className='contenedor-cardal' style={{ background: "#ffffff" }}>
                            <div className='maxcontcard'>
                                <div className='contimg'>
                                    <div className='alt-ing'>
                                        <img src={(this.state.imagenpropiedad === "") ? "https://cdn.pixabay.com/photo/2017/06/09/09/30/swimming-pool-2386261_640.jpg" : "https://numacalendar.com/backnode/numa/fincas/" + this.state.imagenpropiedad + ".jpg"} alt=""></img>
                                        <div>
                                            <img className='logoempre' src={logo} alt={"Logo"} />
                                            <div className='textos'>
                                                <p>Numa</p>
                                                <span>Calendar</span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='contdescripcion'>
                                    <h3>Alquila Nuestra Propiedad</h3>
                                    <div className='services-card'>
                                        <div className='dt-serc001'>
                                            <i className="fa fa-cubes" aria-hidden="true"></i>
                                            <p>Consigue Más Reservas</p>
                                        </div>

                                        <div className='dt-serc001'>
                                            <i className="fa fa-balance-scale" aria-hidden="true"></i>
                                            <p>Ahorra Tiempo en consultas</p>
                                        </div>

                                        <div className='dt-serc001'>
                                            <i className="fa fa-heart-o" aria-hidden="true"></i>
                                            <p>Huéspedes satisfechos</p>
                                        </div>

                                        <div className='dt-serc001'>
                                            <i className="fa fa-diamond" aria-hidden="true"></i>
                                            <p>80% menos trabajo administrativo</p>
                                        </div>



                                    </div>
                                </div>

                            </div>

                        </div>
                    </>

                ) : (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#ffffff" }}>
                        <span style={{ marginTop: 160 }}>Cargando Calendario...</span>
                        <div className="loader">
                            <div className="inner one"></div>
                            <div className="inner two"></div>
                            <div className="inner three"></div>
                        </div>
                    </div>
                )}

            </>

        )
    }
}
