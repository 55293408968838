import React, { Component } from 'react'
import Menu from '../menu/menu'
import Logoilus from "../../img/register.svg"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import './login.css';


const enviardaya = (async (collection, url, tipo) => {
    const querys = await fetch(url, {
        method: tipo,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            collection
        )
    })
    const retorno = await querys.json();
    return retorno;
})


export default class register extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()   // Create a ref object 
        this.planfree = React.createRef()
        this.planbasico = React.createRef()
        this.planpremiun = React.createRef()
    }

    state = {
        nombre: "",
        apellido: "",
        phone: "",
        email: "",
        documento: "",
        password: "",

        planseleccionado: "Plan Basico - $10.000/Mes - 60 dias Gratis",
        loading: false,
    }

    onchangemovil = (e) => {
        this.setState({ phone: e })
    }

    onchangetext = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    activarplanes = (e) => {
        e.preventDefault();
        if (this.myRef) {
            this.myRef.current.classList.toggle('activo')
        }
    }

    activarplan = (plan, valor, e) => {
        e.preventDefault();
        this.setState({ planseleccionado: "Plan " + plan + " -  $" + valor + "/Mes 60 Dias Gratis" })

        if (plan === "free") {
            this.planfree.current.classList.toggle('activo');
            this.planbasico.current.classList.remove("activo");
            this.planpremiun.current.classList.remove("activo");
        } else if (plan === "Basic") {
            this.planbasico.current.classList.toggle('activo');
            this.planfree.current.classList.remove("activo");
            this.planpremiun.current.classList.remove("activo");
        } else {
            this.planpremiun.current.classList.toggle('activo');
            this.planfree.current.classList.remove("activo");
            this.planbasico.current.classList.remove("activo");
        }
    }

    onsubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        let collection = {}
        collection.nombre = this.state.nombre
        collection.apellido = ""
        collection.documento = this.state.documento
        collection.telefono = this.state.phone
        collection.correo = this.state.email
        collection.contrasena = this.state.password
        //setisloading(true);

        const retorno = await enviardaya(collection, global.urlservidor + "crearusuario", "POST");
        //console.log(collection);
        //console.log(retorno);
        this.setState({ loading: false })
        if (retorno[0].status) {
            alert("Revisa tu correo para activar tu cuenta");
        } else {
            alert(retorno[1].causa)
        }
    }

    render() {

        //console.log(this.props)
        return (
            <>
                <Menu></Menu>
                <div style={{ paddingTop: "60px" }}>

                    {this.state.loading && (
                        <div className="cargando">
                        </div>
                    )}

                    <div className="contenedor_login-reg">
                        <div className="formulario_login" style={{ marginBottom: "20px" }}>
                            <div className="img">
                                <img src={Logoilus} alt="login numa calendar"></img>
                            </div>
                            <div className="titulo">
                                <h1>Bienvenido</h1>
                                <p>Registrate, ahorra tiempo y manten sincronizados todos tus calendarios</p>
                            </div>

                            <form style={{ marginTop: "30px" }}>

                                <div className="form-group">
                                    <label>Correo</label>
                                    <p>Te enviaremos un email de confirmación para activar tu cuenta</p>
                                    <input type="mail"
                                        autoComplete="off"
                                        placeholder="tunombre@correo.com"
                                        onChange={this.onchangetext}
                                        name="email"
                                        value={this.state.email} />
                                </div>

                                <div className="form-group">
                                    <label>Nombre Completo</label>
                                    <input type="text"
                                        autoComplete="off"
                                        placeholder="Nombre(s)"
                                        onChange={this.onchangetext}
                                        name="nombre"
                                        value={this.state.nombre} />
                                </div>

                                <div className="form-group">
                                    <label>Contraseña</label>
                                    <input type="password"
                                        autoComplete="off"
                                        className="form-control"
                                        onChange={this.onchangetext}
                                        name="password"
                                        value={this.state.password} />
                                </div>

                                <div className="form-group">
                                    <label>Numero De Telefono</label>
                                    <PhoneInput
                                        placeholder=""
                                        country="CO"
                                        defaultCountry="CO"
                                        value={this.state.phone}
                                        onChange={this.onchangemovil} />
                                </div>





                                <button type="submit" onClick={this.onsubmit} style={{ marginTop: "20px" }} className="btn_log fondo_gb">Crear Cuenta</button>

                            </form>


                        </div>
                    </div>


                </div>
            </>
        )
    }
}
