import React, { Component } from 'react'
import Menu from '../menu/menu'
import Logoilus from "../../img/login.svg"
import './login.css';

const enviardaya = (async (collection, url, tipo) => {
    const querys = await fetch(url, {
        method: tipo,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            collection
        )
    })
    const retorno = await querys.json();
    return retorno;
})

export default class login extends Component {

    state = {
        user: "",
        contra: "",
        tokencalendario: "",
        loading: false
    }
    componentDidMount() {
        const validarurl = this.props.props;
        if (typeof validarurl !== "undefined") {
            this.setState({ tokencalendario: validarurl.match.params.token })            
        }        
    }

    onchangetext = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onsubmit = async (e) => {
        this.setState({ loading: true })
        e.preventDefault();
        let collection = {}
        collection.user = this.state.user
        collection.pass = this.state.contra
        //console.log(collection)
        const retorno = await enviardaya(collection, global.urlservidor + "validarlogin", "POST");
        //console.log(retorno)
        if (retorno[0].status) {
            this.props.funcionlogging(retorno[1].contenido[0]);
            if (this.state.tokencalendario !== "") {
                window.location = '/app/share/' + this.state.tokencalendario;
            } else {
                window.location = '/app/panel';
            }

        }

        this.setState({ loading: false })

    }

    irregistro = (e) => {
        e.preventDefault();
        window.location = '/registro';

    }

    render() {

        return (
            <>
                <Menu></Menu>
                <div style={{ paddingTop: "60px" }}>
                    {this.state.loading && (
                        <div className="cargando">
                        </div>
                    )}
                    <div className="contenedor_login">
                        <div className="formulario_login" style={{ marginBottom: "20px" }}>
                            <div className="img">
                                <img src={Logoilus} alt="login numa calendar"></img>
                            </div>
                            <div className="titulo">
                                <h1>Bienvenido</h1>
                                <p>Iniciar sesión y manten sincronizados todos tus calendarios</p>
                            </div>

                            <form>

                                <div className="form-group">
                                    <label>Usuario</label>
                                    <input type="text"
                                        autoComplete="off"
                                        onChange={this.onchangetext}
                                        name="user"
                                        value={this.state.user} />
                                </div>

                                <div className="form-group">
                                    <label>Contraseña</label>
                                    <input type="password"
                                        autoComplete="off"
                                        className="form-control"
                                        onChange={this.onchangetext}
                                        name="contra"
                                        value={this.state.contra} />
                                </div>

                                <button type="submit" className="btn_log fondo_gb" onClick={this.onsubmit}>Ingresar</button>

                                <p style={{ margin: "20px 0px", textAlign: "center" }}>ó</p>

                                <button className="btn_log fondo_gb2" onClick={this.irregistro} >Registrate</button>

                            </form>




                        </div>


                    </div>
                </div>
            </>
        )

    }
}
