import React, { Component } from 'react'
import './share.css';
import Calendar_Two from '../../img/Calendar_Two.svg'
import Menu from './Menu'


function getToken() {
    const tokenString = localStorage.getItem('tokenapp');
    const userToken = JSON.parse(tokenString);
    return userToken
}

function getParameterByName(name, url) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(url);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export default class sharecalendar extends Component {
    constructor(props) {
        super();
        this.state = {
            linkcalendar: "",
            token: "",
            tokencalendario: "",

            activar: false,
        }
    }

    enviardatosservidor = async (collection, url) => {
        const querys = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                collection
            )
        })
        const retorno = await querys.json();
        return retorno;
    }

    onchangetext = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    agregarcalendario = async (e) => {
        e.preventDefault();
        var const_retorno = getParameterByName("token", this.state.linkcalendar)
        let collection = {}
        collection.url = this.state.linkcalendar;
        collection.idcliente = this.state.token;
        //collection.idfinca = this.state.tokencalendario;
        collection.idfinca = const_retorno;

        const retorno = await this.enviardatosservidor(collection, global.urlservidor + "compartir", "POST");
        if (retorno[0].status) {
            this.setState({ activar: true })
            //this.props.history.push('/app/share/');
        } else {
            alert(retorno[1].causa);
        }
    }

    async componentDidMount() {
        const token = getToken();
        if (token) {
            const validarurl = this.props.match.params.token;
            if (validarurl !== "") {
                let collection = {}
                collection.url = "";
                collection.idcliente = token.cunico;
                collection.idfinca = validarurl;
                const retorno = await this.enviardatosservidor(collection, global.urlservidor + "compartir", "POST");
                //console.log(retorno);
                if (retorno[0].status) {
                    this.setState({ activar: true })
                }
            } else {
                this.setState({ token: token.cunico, tokencalendario: validarurl })
            }
        } else {

            const validarurl = this.props.match.params.token;

            if (validarurl !== "") {
                this.setState({ token: "" })
                this.props.history.push('/app/calendar/' + validarurl);
            } else {
                this.setState({ token: "" })
                this.props.history.push('/login/');
            }
        }
    }

    newcalendar = (e) => {
        e.preventDefault();
        this.props.history.push('/app/panel');
    }

    ircalendario = (e) => {
        e.preventDefault();
        this.setState({ activar: false })
    }

    render() {
        return (
            <div className="dashboard">
                <Menu></Menu>
                <div className="panel_p bgimg">
                    {!this.state.activar ? (
                        <div className="invitado-link">
                            <h1>Link De Calendario</h1>
                            <p>Agrega el calendario, copia y pega el link que fue compartido</p>
                            <div className="link">
                                <input
                                    placeholder="URL usuario"
                                    autoComplete="off"
                                    onChange={this.onchangetext}
                                    name="linkcalendar"
                                    value={this.state.linkcalendar}
                                ></input>
                                <button onClick={this.agregarcalendario}> Agregar Calendario</button>
                            </div>
                        </div>
                    ) : (
                        <div className="invitado-link">
                            <h3>Bienvenido A Numa Calendar</h3>
                            <p>propiedad agregada exitosamente, Consulta tus calendarios</p>
                            <div className="btn_cal">
                                <button onClick={this.newcalendar} style={{ background: "#45cdff" }}>Ir a Calendario</button>
                                <button onClick={this.ircalendario} style={{ background: "" }}>Agregar Nuevo</button>
                            </div>

                            {/* <div className="card_welcom">
                                <div className="imag">
                                    <img src={"https://mifinka.com/backnode/fincas/4/General/WhatsAppImage2019-03-14at4.26.03PM(1).jpeg"}></img>
                                </div>
                                <div className="descr">
                                    <h4>Bienvenido a Finca el triunfo</h4>
                                    <p>propiedad ubicada en medellin, anquioquia, calendario</p>
                                </div>
                            </div> */}
                        </div>
                    )}

                </div>
            </div>
        )
    }
}
