import React, { Component } from 'react'
import './menu.css';
import logo from '../../img/logo.png'
import { Link } from 'react-router-dom'


function getToken() {
    const tokenString = localStorage.getItem('tokenapp');
    const userToken = JSON.parse(tokenString);
    return userToken
}

export default class menu extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()   // Create a ref object 
        this.state = {
            token: ""
        }
    }

    activarmenu = (e) => {
        e.preventDefault();
        if (this.myRef) {
            this.myRef.current.classList.toggle('activo')
        }
    }

    componentDidMount() {
        const token = getToken();
        if (token) {
            this.setState({ token: token.cunico })
        } else {
            this.setState({ token: "" })
        }
    }
    cerrarcuenta = (e) => {
        e.preventDefault();
        localStorage.setItem('tokenapp', null);
        window.location = '/';
    }

    render() {
        return (
            <div className="contenedor-menu">
                <header className="header" id="header">
                    <nav className="nav container">
                        <div className="header__logo">
                            <img className="header__img" src={logo} alt="logo numa calendar" />
                            <h1 className="header__title">Numa<span className="header__light">Calendar</span></h1>
                        </div>

                        <div className="nav_menu" id="nav_menu" ref={this.myRef}>
                            <div className="nav_tittle_movil">
                                <img className="header__img" src={logo} alt="logo numa calendar" />
                                <h2>Numa<span>Calendar</span></h2>
                            </div>
                            <ul className="nav_list grid">
                                <li className="nav_item">
                                    <Link to={"/"} className="nav__link">
                                        <i className="uil uil-home"></i>
                                        Inicio
                                    </Link>
                                </li>

                                <li className="nav_item">
                                    <Link to={"/"} className="nav__link">
                                        <i className="uil uil-pricetag-alt"></i>
                                        Precios
                                    </Link>
                                </li>

                                {this.state.token === "" ? (
                                    <li className="nav_item">
                                        <Link to={"/registro"} className="nav__link">
                                            <i className="uil uil-award"></i>
                                            Registro
                                        </Link>
                                    </li>
                                ) : (
                                    <li className="nav_item">
                                        <Link to={"/app/panel"} className="nav__link">
                                            <i className="uil uil-award"></i>
                                            Mi cuenta
                                        </Link>
                                    </li>
                                )}


                                {this.state.token === "" ? (
                                    <li className="nav_item">
                                        <Link to={"/login"} className="signing">Iniciar Sesión</Link>
                                    </li>
                                ) : (
                                    <li className="nav_item">
                                        <button className="signing" onClick={this.cerrarcuenta} > Cerrar Sesión</button>
                                    </li>
                                )}
                            </ul>
                            <i className="uil uil-times nav__close"></i>
                        </div>

                        <div className="nav__btns">
                            <div className="nav__toggle" id="nav-toggle" onClick={this.activarmenu}>
                                <i className="uil uil-apps"></i>
                            </div>

                        </div>
                    </nav>
                </header>
            </div>
        )
    }
}
