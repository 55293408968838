import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React from 'react';
import Login from "./component/login/login"
import Registro from "./component/login/register"
/* import Menu from "./component/menu/menu" */
import Home from './component/Home/Home'
import Compartir from './component/Aplicicacion/sharecalendar'
import Compartircalendar from './component/Aplicicacion/Calendarlogin'
import Panel from './component/Aplicicacion/Panel'
import Terminosycon from './component/Ayuda/Terminos'
import './App.css';

function setToken(userToken) {
  localStorage.setItem('tokenapp', JSON.stringify(userToken));
}

function getToken() {
  const tokenString = localStorage.getItem('tokenapp');
  const userToken = JSON.parse(tokenString);  
  return userToken
}


function App() {
  //global.urlservidor = 'https://mifinka.com/backnode/aplicacion/';  
  global.urlservidor = 'https://numacalendar.com/backnode/aplicacion/';  
  const token = getToken();
  
  /* if (!token) { */
    return (
      <div className="container_p">
        <Router>
          
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/login" render={() => {
              return <Login funcionlogging={setToken}></Login>
            }} />
            <Route exact path="/login/:token" render={(props) => {
              return <Login funcionlogging={setToken} props={props}></Login>
            }}/>
            <Route exact path="/registro" component={Registro} />
            {/* --------------Menu aplicacion ------------------- */}
            <Route  path="/app/share/:token" exact component={Compartir} />
            <Route  path="/app/share/:token" exact component={Compartir} />
            <Route  path="/app/calendar/:token" exact component={Compartircalendar} />
            {/* <Route exact path="/app/share/" component={Compartir} /> */}
            <Route exact path="/app/panel" component={Panel} />

            <Route exact path="/terminosycondiciones" component={Terminosycon} />
            {/* <Route exact path="/app/board" component={Registro} /> */}
          </Switch>
        </Router>
      </div>
    )
  /* } else {
    return (
      <div>
        <h1>Hola</h1>
      </div>
    )
  } */


}

export default App;
