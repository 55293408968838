import React, { Component } from 'react'
import './share.css';
import mansvg from "../../img/man.svg"
import { Link } from 'react-router-dom'

function getToken() {
    const tokenString = localStorage.getItem('tokenapp');
    const userToken = JSON.parse(tokenString);
    return userToken
}


export default class Menu extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()   // Create a ref object     
        this.state = {
            nombre: "",
            rol: "",
        }
    }

    activarmenu = (e) => {
        e.preventDefault();
        if (this.myRef) {
            this.myRef.current.classList.toggle('activo')
        }
    }

    componentDidMount() {
        const token = getToken();        
        if (token) {
            this.setState({
                nombre: token.nombre + " " +  token.apellido,
                rol: token.correo,
            })
        }
    }

    cerrarlog =(e)=>{
        //e.PreventDefault();
        localStorage.setItem('tokenapp', null);
        window.location = '/';
    }

    render() {
        return (

            <div className="_sidebar" ref={this.myRef}>
                <div className="logo_content">
                    <div className="logo">
                        <i className="uil uil-award"></i>
                        <div className="logo_name">Numa</div>
                    </div>
                    <i className="uil uil-align-justify" id="btn" onClick={this.activarmenu}></i>
                </div>
                <ul className="nav_lista">
                    {/* <li>
                            <a href="/#">
                                <i className="uil uil-apps"></i>
                                <span className="link_name">
                                    Inicio
                                </span>
                            </a>
                            <span className="tootip">Inicio</span>
                        </li> */}

                    <li>
                        <Link to={"/app/panel"}>
                            <i className="uil uil-calendar-alt"></i>
                            <span className="link_name">
                                Calendarios
                            </span>
                        </Link>
                        <span className="tootip">Calendarios</span>
                    </li>

                    <li>
                        <Link to={"/app/share"}>
                            <i className="uil uil-user-check"></i>
                            <span className="link_name">
                                Invitaciones
                            </span>
                        </Link>
                        <span className="tootip">Invitaciones</span>
                    </li>

                    <li>
                        <Link to={"/"}>
                            <i className="uil uil-cloud-question"></i>
                            <span className="link_name">
                                Ayuda
                            </span>
                        </Link>
                        <span className="tootip">Ayuda</span>
                    </li>
                </ul>
                <div className="profile_content">
                    <div className="profile">
                        <div className="p_detalle">
                            <img src={mansvg} alt="Perfil de usuario"></img>
                            <div className="name_job">
                                <div className="nameuser">{this.state.nombre}</div>
                                <div className="job">Administrador</div>
                            </div>
                        </div>
                        <i className="uil uil-signout" id="logout" onClick={this.cerrarlog}></i>
                    </div>
                </div>
            </div>
        )
    }
}
